import Cookies from "js-cookie";
import { navigate } from "@reach/router";
import Config from "../config";

const COOKIE_NAME = "linepilot";
const LC_USER_DATA = "lc-linepilot-user";

/** User session management */
const Session = {
  /**
   * Check if a user is logged in
   * @returns {boolean}
   */
  isLoggedIn: () => {
    return !!Cookies.get(COOKIE_NAME);
  },

  refreshUserData: async () => {
    return await refreshUserData();
  },

  /**
   * Return user data if user is authenticated
   * @returns {null|Object}
   */
  getUserData: async () => {
    if (!Session.isLoggedIn()) {
      return null;
    }
    let data = JSON.parse(localStorage.getItem(LC_USER_DATA));
    if (!data) {
      //Data won't be store in localStorage if using an OAuth callback (e.g. Xero SSO) OR password resets - so we need to fetch
      return await refreshUserData();
    }
    return data.user;
  },

  /**
   * Set user data in local-storage
   * @param jsonData {Object} json
   */
  setUserData: (jsonData) => {
    localStorage.setItem(LC_USER_DATA, JSON.stringify(jsonData));
  },

  logout: async (redirect = false) => {
    await localStorage.removeItem(LC_USER_DATA);
    await localStorage.removeItem("isOnboarding");
    await Cookies.remove(COOKIE_NAME, { domain: Config.cookieDomain });
    if (redirect) {
      navigate("/login?redirect=" + redirect);
    } else {
      navigate("/login");
    }
  },
};

const refreshUserData = async () => {
  if (!Session.isLoggedIn()) {
    return null;
  }

  let requestUrl = Config.api.root + "/admin/user/check/";
  let response = await fetch(requestUrl, Config.api.options);
  if (response.status !== 200) {
    throw response.status;
  }
  response = await response.json();
  if (response.error) {
    throw response.error;
  }
  Session.setUserData(response);
  return response.user;
}

export default Session;
