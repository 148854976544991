import React from "react";
import zxcvbn from "zxcvbn";

import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";
import Config from "../config";
import BlankWrapper from "../wrappers/BlankWrapper";
import { navigate } from "@reach/router";

class PasswordChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      error: false,
      errorDetails: false,
      passwordScore: 0,
    };
  }
  componentDidMount = async () => {
    document.title = "Change Password" + Config.titleSuffix;
  };
  async handleSubmit(e) {
    e.preventDefault();
    try {
      this.setState({ loading: true });
      let requestUrl = Config.api.root + "/auth/password/create";
      let options = {
        method: "POST",
        body: JSON.stringify({ password: this.state.password }),
      };
      let response = await fetch(requestUrl, {
        ...options,
        ...Config.api.options,
      });
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.success) {
        this.setState({ success: true, loading: false });
      }
    } catch (error) {
      this.setState({ 
        loading: false,
        error: true
      });
    }
  }
  passwordChange(e) {
    var passwordValue = e.target.value;
    var passwordVerdict = zxcvbn(passwordValue).score + 1;
    this.setState({
      password: passwordValue,
      passwordScore: parseInt(passwordVerdict),
    });
  }
  render() {
    const passwordMeter = [1, 2, 3, 4, 5].map((i) => {
      let classes = "rounded-xl transition-colors h-2 ";

      if (i <= this.state.passwordScore && this.state.passwordScore <= 2) {
        classes += "bg-red-400";
      } else if (i <= this.state.passwordScore && this.state.passwordScore <= 4) {
        classes += "bg-yellow-400 ";
      } else if (i <= this.state.passwordScore && this.state.passwordScore === 5) {
        classes += "bg-green-500 ";
      } else {
        classes += "bg-gray-200 ";
      }

      return (
        <div className="w-1/5 px-1" key={i}>
          <div className={classes}></div>
        </div>
      );
    });

    const contents = (
        <div className="flex flex-row items-center justify-center max-w-screen-xl min-h-screen mx-auto">
          <div className="flex flex-col items-center w-full max-w-sm gap-4 text-center">
            <h2>Change Password</h2>
            <p>Enter your new password to update it</p>

            <Message open={this.state.loading} type="loading" title="Updating Password..." />
            <Modal
              open={this.state?.error}
              type="error"
              title="Reset Failed"
              message="Couldn't reset your password"
              setClose={() => {
                this.setState({ error: false }),
                  setTimeout(() => this.setState({ errorDetails: false }), 500);
              }}
            />
            <Modal
              open={this.state?.success}
              type="success"
              title="Password Changed"
              message="Your password has been successfully changed"
              setClose={() => navigate('/')}
            />

            <form className="w-full mx-auto" onSubmit={(e) => this.handleSubmit(e)}>
                  <div className="mt-4">
                    <label htmlFor="password" className="block m-0 text-left">
                      Create a Password
                    </label>
                    <div>
                      <input
                        name="password"
                        //on-input('checkStrength')
                        value={this.state.password}
                        type="password"
                        onChange={(e) => this.passwordChange(e)}
                        required
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
                      />
                    </div>
                    <div className="flex mt-1 -mx-1">{passwordMeter}</div>
                  </div>

                  <div className="mt-4 text-center">
                      <input className="button-primary" type="submit" value="Change Password" disabled={this.state.loading} />
                  </div>
            </form>
          </div>
        </div>

    );
    return this.props?.addWrapper ? (<BlankWrapper>{contents}</BlankWrapper>) : contents;
  }
}

export default PasswordChange;
