import React from "react";
import { Link } from "@reach/router";
import { motion } from "framer-motion";
import Config from "../config";
import Session from "../components/Session";
import Confetti from "react-confetti";

class SubscriptionConfirmed extends React.Component {
  componentDidMount = async () => {
    document.title = `Subscription Confirmed ${Config.titleSuffix}`;
    await Session.refreshUserData();
  };

  render() {
    return (
      <motion.div
        key="3"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Confetti
          className="w-screen h-screen"
          colors={["#fbbf24", "#fcd34d", "#fde68a"]}
          numberOfPieces={1000}
          runTime={5000}
          recycle={false}
    
          drawShape={(ctx) => {
            ctx.beginPath();
            ctx.rect(-5, -5, 10, 10);
            ctx.fill();
            ctx.closePath();
          }}
        />
        <div className="flex flex-col items-center my-36">
          <h2 className="mt-6 text-2xl text-center">Subscription Confirmed</h2>

          <div className="max-w-lg mx-auto text-center">
            <p className="max-w-sm mx-auto mt-6 text-center text-green-800">
              Thank you - we really appreciate your business!
            </p>
            <p className="max-w-sm mx-auto my-6 text-sm text-center text-gray-500">
              If you need to manage your billing details or view invoices, just use the billing link
              on your dashboard.
            </p>

            <Link className="button-primary" to="/">
              Return to Dashboard
            </Link>
          </div>
        </div>
      </motion.div>
    );
  }
}

export default SubscriptionConfirmed;
