import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XMarkIcon, SparklesIcon } from "@heroicons/react/24/outline";

export default function Modal({ open, title, message, setClose, type, icon, buttonText, confirmText, confirmAction }) {

  if (type === "success") {
    icon = (
      <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
        <CheckIcon className="w-6 h-6 text-green-600" aria-hidden="true" />
      </div>
    );
  }
  if (type === "upgrade") {
    icon = (
      <div className="flex items-center justify-center w-12 h-12 mx-auto rounded-full bg-amber-500">
        <SparklesIcon className="w-6 h-6 text-white" aria-hidden="true" />
      </div>
    );
    message = (
      <>
        <p>The following feature is not available in your current plan:</p>
        <p className="mt-2">{message}</p>
      </>
    );
  }

  if (type === "error") {
    icon = (
      <div className="flex items-center justify-center w-12 h-12 mx-auto bg-red-100 rounded-full">
        <XMarkIcon className="w-6 h-6 text-red-600" aria-hidden="true" />
      </div>
    );
    message = (
      <>
        <p>{message}</p>
        <p className="mt-2">
          If the issue continues, reach out to our{" "}
          <a href="https://www.linepilot.co">support team</a>
        </p>
      </>
    );
  }

  return (
    <Transition.Root show={open !== false} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform shadow-xl card sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div>
                  {icon}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h2" className="">
                      {title}
                    </Dialog.Title>
                    {message && <div className="mt-2 text-sm">{message}</div>}
                  </div>
                </div>
                <div className="mt-5 text-center sm:mt-6">
                  {confirmText ? (
                    <div className="flex gap-5 mx-auto w-fit">
                      <button type="button" className="button-text" tabIndex="1" onClick={setClose}>
                        {buttonText || "Cancel"}
                      </button>
                      <button
                        type="button"
                        className="button-primary"
                        tabIndex="2"
                        onClick={confirmAction}
                      >
                        {confirmText}
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="button-primary"
                      tabIndex="1"
                      onClick={() => setClose()}
                    >
                      {buttonText || "Close"}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
