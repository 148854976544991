const config = {
  api: {
    root: process.env.REACT_APP_API_ROOT,
    options: {
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  },
  cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN,
  titleSuffix: " - LinePilot",
  selectTheme: (theme) => ({
    ...theme,
    borderRadius: 2,
    colors: {
      ...theme.colors,
      neutral20: "#E1E0DC",
      primary25: "#EAE8E5",
      primary50: "#EAE8E5",
      primary75: "#E1E0DC",
      primary: "#30A689",
    },
  }),
  pricing: {
    starter: {
      monthly: process.env.REACT_APP_PRICE_STARTER_MONTHLY,
      annual: process.env.REACT_APP_PRICE_STARTER_ANNUAL,
    },
    pro: {
      monthly: process.env.REACT_APP_PRICE_PRO_MONTHLY,
      annual: process.env.REACT_APP_PRICE_PRO_ANNUAL,
    },
  },
  stripe: {
    publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  },
};

export default config;
