import React from "react";
import ConditionSelector from "./ConditionSelector";
import { Bars3Icon, PencilIcon, PlusIcon, TrashIcon, WrenchIcon } from '@heroicons/react/24/solid';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addActiveProperty: false,
    };
  }

  addFilter() {
    let newFilterData = {
      property: "",
      value: "",
      id: Math.random().toString(36).substring(7),
    };
    this.props.updateFilters([ ...this.props.filters, newFilterData ]);
  }
  removeFilter(id) {
    let newFilters = this.props.filters.filter((f) => f.id !== id);
    this.props.updateFilters(newFilters);
  }
  updateFilter(id, property, value = false, operator) {
    let newFilters = this.props.filters.map((f) => {
      if (f.id === id) {
        f.property = property;
        f.value = value;
        f.operator = operator;
      }
      return f;
    });
    this.props.updateFilters(newFilters);
  }

  render() {
  
    const dataEntry = this.props.filters.length > 0 && this.props.filters.map((f, index) => {
      return (
        <ConditionSelector
          data={f}
          key={index}
          index={index}
          dealPropertyDefinitions={this.props.dealPropertyDefinitions}
          dealStages={this.props.dealStages}
          updateFilter={this.updateFilter.bind(this)}
          removeFilter={this.removeFilter.bind(this)}
        />
      );
    });
    return (
      <div className="relative flex flex-col my-5">
        <div className="flex flex-col ">{dataEntry}</div>
        <div
          className={
            this.props.filters.length > 0
              ? "flex w-full justify-center -mb-5 mt-5"
              : "flex flex-col items-center w-full gap-5 p-8 border border-tone-500 rounded-3xl"
          }
        >
          <div className="">
            <button
              className="button-low"
              onClick={(e) => {
                this.addFilter();
              }}
            >
              <PlusIcon className="inline w-4 mr-1" />
              Add Filter
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Filters;
