import React from "react";
import { Link, navigate } from "@reach/router";
import { motion } from "framer-motion";
import { DateTime } from "luxon";

import Config from "../config";
import Session from "../components/Session";
import LoadingState from "../components/states/LoadingState";
import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";


class Support extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      loading: false,
      error: false,
      errorDetails: false,
      success: false,
    };
    this._isMounted = false;
  }

  componentDidMount = async () => {
    document.title = `Support ${Config.titleSuffix}`;
    this._isMounted = true;
    let user = await Session.getUserData();

    this.setState({ pageLoading: false, code: user.referralCode });
  };
  
  componentWillUnmount() {
    this._isMounted = false;
  }
  
 


  render() {

  

  return (
    <>
      {this.state.pageLoading ? (
        <LoadingState />
      ) : (
        <>
          <motion.div
            key="1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex flex-row h-screen max-w-screen-xl gap-10 mx-auto">
              <div className="flex-row flex-1 w-full">
                <header className="flex flex-row pb-10 mb-5 border-b mt-36 border-tone-500">
                  <div className="flex flex-col items-baseline flex-grow gap-5">
                    <h1 className="">Support</h1>
                    <p>Watch our getting started walkthrough video below, which will walk you through getting started with LinePilot and creating your first template.</p>
                    <p>
                      A full knowledge base and support system is coming soon to LinePilot.
                      <br />
                      Until then, our team are ready to help, you can contact us on{" "}
                      <a href="mailto:support@linepilot.co">support@linepilot.co</a>, or book a call
                      below
                      <br />
                      <br />
                      <a
                        href="https://meetings.hubspot.com/hbevan/linepilot-support-call"
                        className="button"
                      >
                        Book a Support Call
                      </a>
                    </p>
                  </div>
                </header>
                <div className="h-screen my-10">
                    <iframe
                      src="https://www.loom.com/embed/09de6751e02a4f31917d057aecec1ae4?sid=65fe5e95-b31d-4788-8708-661aa7d22ef6"
                      frameborder="0"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowfullscreen
                      className="w-full h-full "
                    ></iframe>
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </>
  );
  }
}

export default Support;
