import React from "react";

import Session from "../components/Session";
import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";
import Config from "../config";
import { LockClosedIcon } from "@heroicons/react/24/outline";

class InviteCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      errorDetails: false,
      success: false,
      code: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);

  }
  componentDidMount = async () => {
    document.title = `Register ${Config.titleSuffix}`;
  };
  async handleSubmit(e) {
    e.preventDefault();
    console.log(this.state.data);
    try {
      this.setState({ loading: true });
      let options = {
        method: "POST",
        body: JSON.stringify({code: this.state.code}),
      };
      let requestUrl = Config.api.root + "/admin/check-code";
      let response = await fetch(requestUrl, {
        ...options,
        ...Config.api.options,
      });
      console.log(response);
      
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      console.log(response);
      localStorage.setItem('referrerId',response.referrerId)
      
      if (response.error) {
        throw response.error;
      }

      this.props.updateOnboarding(1);
      this.setState({ loading: false });

    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
        error: true,
        errorDetails: {
          title: "Check Failed",
          message:
            typeof error === "string"
              ? error
              : "We couldn't find that code.",
        },
      });
    }
  }

  render() {

    
    return (
      <div className="flex flex-col items-center my-36">
        <Message open={this.state.loading} type="loading" title="Checking invite code..." />
        <Modal
          open={this.state?.error}
          type="error"
          title={this.state.errorDetails.title}
          message={this.state.errorDetails.message}
          setClose={() => {
            this.setState({ error: false }),
              setTimeout(() => this.setState({ errorDetails: false }), 500);
          }}
        />

        <h2 className="flex items-center gap-3"><LockClosedIcon className="w-5 h-5" /> VIP access only</h2>
        <form className="max-w-sm mt-4 " onSubmit={(e) => this.handleSubmit(e)}>
          <p className="text-sm text-center">
            LinePilot is not currently open to the public. To request access, join the waitlist at{" "}
            <a href="https://www.linepilot.co">linepilot.co</a>
          </p>
          <div className="mt-4">
            <label htmlFor="company" className="block m-0 text-sm font-medium text-gray-700">
              Enter your invite code to start
            </label>
            <div>
              <input
                type="text"
                value={this.state.code}
                onChange={(e) => this.setState({ code: e.target.value })}
                required
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
              />
            </div>
          </div>{" "}
          <div className="mt-6 text-center">
            <input
              className="button-primary"
              type="submit"
              value="Check Code"
              disabled={this.state.loading}
            />
          </div>
        </form>
      </div>
    );
  }
}

export default InviteCheck;
