import React from "react";

import Session from "../components/Session";
import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";
import Config from "../config";

class Survey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      errorDetails: false,
      success: false,
      data: {
        purpose: [],
        type: [],
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formUpdate = this.formUpdate.bind(this);

  }
  componentDidMount = async () => {
    document.title = `About You ${Config.titleSuffix}`;
    const user = await Session.getUserData();
    this.setState({ user });
  };
  async handleSubmit(e) {
    e.preventDefault();
    console.log(this.state.data);
    try {
      this.setState({ loading: true });
      let options = {
        method: "POST",
        body: JSON.stringify(this.state.data),
      };
      let requestUrl = Config.api.root + "/admin/survey";
      let response = await fetch(requestUrl, {
        ...options,
        ...Config.api.options,
      });
      console.log(response);
      
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.error) {
        throw response.error;
      }

      this.props.updateOnboarding(2);
      this.setState({ loading: false });

    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
        error: true,
        errorDetails: {
          title: "Registration Failed",
          message:
            typeof error === "string"
              ? error
              : "We were unable to register your account. Please try again.",
        },
      });
    }
  }

  async formUpdate(e) {
    console.log(e.target.value);
    let fieldData = this.state.data[e.target.name];
    if(e.target.type === "radio") fieldData = [];
    if (e.target.checked) {
      fieldData.push(e.target.value);
    } else {
      fieldData = fieldData.filter((item) => item !== e.target.value);
    }
    let data = this.state.data;
    data[e.target.name] = fieldData;
    await this.setState({ data: data });
  }

  render() {

    const FormField = (props) => (
      <label htmlFor={props.value} className="flex items-center w-full gap-2">
        <input
          type={props.type || "checkbox"}
          id={props.value}
          name={props.name}
          value={props.value}
          checked={this.state.data[props.name].includes(props.value)}
          onChange={(e) => this.formUpdate(e)}
          className="w-4 h-4 text-primary border-tone-500 focus:ring-primary"
        />
        <span className="">{props.label}</span>
      </label>
    );
    
    return (
      <div className="flex flex-col items-center my-36">
        <Message open={this.state.loading} type="loading" title="Creating account..." />
        <Modal
          open={this.state?.error}
          type="error"
          title={this.state.errorDetails.title}
          message={this.state.errorDetails.message}
          setClose={() => {
            this.setState({ error: false }),
              setTimeout(() => this.setState({ errorDetails: false }), 500);
          }}
        />

        <h2>Tell us about you</h2>

        <form
          className="flex flex-col w-full max-w-lg gap-6 mx-auto mt-6"
          id="linepilot-survey"
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <input type="email" className="hidden" value={this.state.user?.username} />
          <div className="flex flex-col gap-2">
            <label>What are you planning to use line items for?</label>

            <div className="flex flex-row flex-wrap gap-6">
              <div className="flex flex-col flex-1 gap-2">
                <FormField key="quotes" name="purpose" value="quotes" label="HubSpot Quotes" />
                <FormField
                  key="invoices"
                  name="purpose"
                  value="invoices"
                  label="HubSpot Invoices"
                />
                <FormField
                  key="payments"
                  name="purpose"
                  value="payments"
                  label="HubSpot Payments"
                />
              </div>
              <div className="flex flex-col flex-1 gap-2">
                <FormField key="reporting" name="purpose" value="reporting" label="Reporting" />
                <FormField
                  key="invoicestack"
                  name="purpose"
                  value="invoicestack"
                  label="Invoice Stack"
                />
                <FormField
                  key="software"
                  name="purpose"
                  value="software"
                  label="External Software"
                />

                {this.state.data.purpose.includes("software") && (
                  <div className="flex flex-col max-w-sm mt-2 ml-6 group">
                    <label>Which software?</label>
                    <input
                      name="softwareName"
                      defaultValue={this.state.data.softwareName}
                      onChange={(e) =>
                        this.setState({
                          data: { ...this.state.data, ...{ softwareName: e.target.value } },
                        })
                      }
                      type="text"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="">What's your relationship to HubSpot?</label>
            <FormField name="type" type="radio" value="hubspotter" label="I work there" />
            <FormField
              name="type"
              type="radio"
              value="partner"
              label="I'm a consultant or solutions partner"
            />
            <FormField name="type" type="radio" value="customer" label="I'm a HubSpot customer" />

            {this.state.data.type.includes("customer") && (
              <div className="flex flex-col max-w-sm mt-2 ml-6 group">
                <label>Are you working with a Solutions Partner? If so who?</label>
                <input
                  name="partnerName"
                  defaultValue={this.state.data.partnerName}
                  onChange={(e) =>
                    this.setState({
                      data: { ...this.state.data, ...{ partnerName: e.target.value } },
                    })
                  }
                  type="text"
                />
              </div>
            )}
          </div>
          <div className="mt-6 text-center">
            {this.props.onboarding && (
              <div className="mt-4">
                <input
                  type="submit"
                  value={"Continue"}
                  onClick={(e) => this.state.connected && this.props.updateOnboarding(2)}
                  className="button-primary"
                />
              </div>
            )}{" "}
          </div>
        </form>
      </div>
    );
  }
}

export default Survey;
