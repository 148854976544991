import React from "react";
import { Router, Redirect, globalHistory } from "@reach/router";
import { AnimatePresence } from "framer-motion";

import Error from "./admin/Error";
import Login from "./admin/Login";
import PasswordRequest from "./admin/PasswordRequest";
import PasswordChange from "./admin/PasswordChange";

import Session from "./components/Session";

import Onboarding from "./wrappers/Onboarding";
import Dashboard from "./wrappers/Dashboard";

import HubSpot from "./pages/HubSpot";
import Billing from "./pages/Billing";
import SubscriptionConfirmed from "./pages/SubscriptionConfirmed";
import TemplateList from "./pages/TemplateList";
import Template from "./pages/Template";
import Invite from "./pages/Invite";
import Support from "./pages/Support";

import ErrorBoundary from "./components/states/ErrorBoundary";
import ErrorState from "./components/states/ErrorState";

import "./assets/tailwind.css";

const PrivateRoute = ({ component: Component, ...props }) => {
  const userData = Session.getUserData();
  const isLoggedIn = !!userData;
  return (
    <div className="h-full">{isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />}</div>
  );
};

// Tracking Code HubSpot & GA
globalHistory.listen(function ({ location }) {
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push(["setPath", location.pathname]);
  _hsq.push(["trackPageView"]);
  // Reload HubSpot CTA embeds otherwise they don't show on page navigation
  window.hubspot_web_interactives_running = false;
  var script = document.createElement("script");
  script.src = "https://js.hubspot.com/web-interactives-embed.js";
  document.body.appendChild(script);
});

// Container Component
class App extends React.Component {

  componentDidMount = async () => {};


  render() {
    return (
      <ErrorBoundary>
        <AnimatePresence>
          <Router>
            <PrivateRoute path="/" exact component={Dashboard}>
              <PrivateRoute path="/" exact component={TemplateList}></PrivateRoute>
              <PrivateRoute path="/template/:templateId" component={Template}></PrivateRoute>
              <PrivateRoute path="/hubspot" component={HubSpot} onboarding={false}></PrivateRoute>
              <PrivateRoute path="/billing" component={Billing} onboarding={false}></PrivateRoute>
              <PrivateRoute path="/support" component={Support} onboarding={false}></PrivateRoute>
              <PrivateRoute path="/invite" component={Invite} onboarding={false}></PrivateRoute>
              <PrivateRoute
                path="/subscription-confirmed"
                component={SubscriptionConfirmed}
              ></PrivateRoute>
              <PrivateRoute path="/password" component={PasswordChange}></PrivateRoute>
              <PrivateRoute
                default
                h1="Page not found"
                fullPage={false}
                component={ErrorState}
              ></PrivateRoute>
            </PrivateRoute>
            <Login component={Login} exact path="/login/*" />
            <Error exact path="/error" />
            <PasswordRequest path="/login/password-reset" addWrapper={true} />
            <Onboarding path="/onboarding" />
            <ErrorState default h1="Page Not Found" />
          </Router>
        </AnimatePresence>
      </ErrorBoundary>
    );
  }
}

export default App;
