import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Link } from "@reach/router";

const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block text-left">
      <button onClick={toggleMenu} className="flex items-center gap-2 text-primary">
        My Account
        <ChevronDownIcon className="w-4 h-4 ml-1" />
      </button>
      {isOpen && (
        <div className="absolute right-0 w-40 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-tone-700 ring-opacity-5">
          <div
            className="flex flex-col py-1 text-right"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <Link className="px-5 py-2" to="/hubspot" onClick={toggleMenu}>
              HubSpot
            </Link>
            <Link className="px-5 py-2" to="/billing" onClick={toggleMenu}>
              Billing
            </Link>
            <Link className="px-5 py-2" to="/password" onClick={toggleMenu}>
              Change Password
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
