import React from "react";
import ErrorState from "../components/states/ErrorState";
import LoadingState from "../components/states/LoadingState";
import BlankWrapper from "../wrappers/BlankWrapper";

import Session from "../components/Session";
import Config from "../config";

class Error extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidMount = async () => {
    document.title = "Error" + Config.titleSuffix;

    try {
      let requestUrl = Config.api.root + "/admin/error/get";
      let response = await fetch(requestUrl, Config.api.options);
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (!response.error.h1) {
        throw new Error("error getting error");
      }
      this.setState({
        h1: response.error.h1,
        message: response.error.message.length > 0 ? response.error.message : "",
        continueUrl: response.error.continueUrl,
        loading: false,
      });
      console.log(response);
    } catch (error) {
      if (error === 403) {
        Session.logout(this.props.location.pathname);
      }
      this.setState({ loading: false });
      console.error(error);
    }
  };
  render() {
    return (
      <>
        {this.state.loading ? (
          <LoadingState />
        ) : (
          <BlankWrapper>

            <ErrorState
              h1={this.state.h1}
              message={this.state.message}
              continueUrl={this.state.continueUrl}
            />
          </BlankWrapper>
        )}
      </>
    );
  }
}

export default Error;
