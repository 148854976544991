import React from "react";
import LPLogo from "../assets/images/linepilot-logo-v2.png";

const BlankWrapper = ({ children }) => {
  // Your common template layout here
  return (
    <div className="w-full min-h-screen md:flex-row">
      <div className="fixed top-0 z-20 flex flex-row items-center w-full px-5 py-2 bg-gradient-to-b from-tone-100 via-tone-100 to-transparent">
        <a href="https://www.linepilot.co" className="relative block -left-5">
          <img src={LPLogo} className="w-auto h-12" alt="LinePilot Logo" />
        </a>
        <nav className="flex flex-row items-center justify-end flex-grow gap-10 text-sm">
          <a href="/login" className="">
            Login
          </a>
          <a href="/onboarding" className="button">
            Sign Up Free
          </a>
        </nav>
      </div>
      <div className="w-full min-h-screen">{children}</div>
    </div>
  );
  }

export default BlankWrapper;
