import React from "react";
import { Redirect } from "@reach/router";
import { motion } from "framer-motion";

import Session from "../components/Session";
import Register from "../pages/Register";
import Survey from "../pages/Survey";
import CreateTemplate from "../pages/CreateTemplate";
import HubSpot from "../pages/HubSpot";
import InviteCheck from "../pages/InviteCheck";
import BlankWrapper from "./BlankWrapper";

class Onboarding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "loading",
      onboarding: 0,
    };
  }

  componentDidMount = async () => {
    if (this.state.onboarding === 0) {
      // extract onboarding status from localstorage (keep state between redirects)
      let onboarding = localStorage.getItem("isOnboarding")
        ? localStorage.getItem("isOnboarding")
        : 0;

      let userData = await Session.getUserData();
      // if userData has a higher onboarding step then use that instead
      if (onboarding === "complete" || (userData && userData.onboarding === "complete")) {
        this.setState({ onboarding: "complete" });
      } else {
        if (
          userData &&
          userData.hasOwnProperty("onboarding") &&
          parseInt(userData.onboarding) > parseInt(onboarding)
        ) {
          onboarding = userData.onboarding;
        }
        this.setState({ onboarding: parseInt(onboarding) });
      }
    }
  };

  updateOnboarding = (value) => {
    // pass this down in props
    localStorage.setItem("isOnboarding", value);
    this.setState({ onboarding: value });
    // Onboarding status is also stored in DB as a result of backend calls
  };

  onboardingSteps = (onboarding) => {
    switch (onboarding) {
      case 1:
        return <Survey onboarding={true} updateOnboarding={this.updateOnboarding.bind(this)} />;
      case 2:
        return <HubSpot onboarding={true} updateOnboarding={this.updateOnboarding.bind(this)} />;
      case 3:
        return (
          <CreateTemplate onboarding={true} updateOnboarding={this.updateOnboarding.bind(this)} />
        );
      case "complete":
        return <Redirect noThrow={true} to="/" />;
      default:
        return <Register updateOnboarding={this.updateOnboarding.bind(this)} />;
    }
  };

  progressBar = (onboarding) => {
    let steps = [1, 2, 3, 4];
    return steps.map((step, index) => {
      if (step === onboarding) {
        return (
          <a
            key={index}
            href={step.href}
            className="relative flex items-center justify-center"
            aria-current="step"
          >
            <span className="absolute flex w-5 h-5 p-px" aria-hidden="true">
              <span className="w-full h-full rounded-full bg-secondary" />
            </span>
            <span
              className="relative block w-2.5 h-2.5 bg-primary rounded-full"
              aria-hidden="true"
            />
          </a>
        );
      } else {
        let classes =
          step < onboarding
            ? "block w-2.5 h-2.5 bg-primary rounded-full"
            : "block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400";
        return (
          <li key={index}>
            <span className={classes}></span>
          </li>
        );
      }
    });
  };

  stepsList = (onboarding) => {
    let steps = [
      "Register for a free account",
      "Tell us about you",
      "Connect to HubSpot",
      "Set up your first template",
      "Go live",
    ];
    return steps.map((step, index) => (
      <li key={index} className="relative pb-3 list-none">
        {index + 1 < steps.length ? (
          <div
            className={
              (index < onboarding ? "bg-primary" : "bg-tone-500") +
              " -ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full"
            }
            aria-hidden="true"
          ></div>
        ) : null}

        <a
          href="#"
          onClick={index < onboarding && index > 0 ? () => this.updateOnboarding(index) : null}
          className={
            (index < onboarding && index > 0 ? "cursor-pointer" : "cursor-default") +
            " relative flex items-center group"
          }
        >
          {index < onboarding ? (
            <span className="flex items-center h-9">
              <span className="relative z-10 flex items-center justify-center w-8 h-8 rounded-full bg-primary group-hover:bg-primary">
                <svg
                  className="w-5 h-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </span>
          ) : (
            <>
              {index === onboarding ? (
                <span className="flex items-center h-9" aria-hidden="true">
                  <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white border-2 rounded-full border-primary">
                    <span className="h-2.5 w-2.5 bg-primary rounded-full"></span>
                  </span>
                </span>
              ) : (
                <span className="flex items-center h-9" aria-hidden="true">
                  <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white border-2 rounded-full border-tone-500 group-hover:border-putty">
                    <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-tone-500"></span>
                  </span>
                </span>
              )}
            </>
          )}

          <span
            className={
              (index <= onboarding ? "text-primary " : " text-tone-900") +
              " flex flex-col min-w-0 ml-4"
            }
          >
            {step}
          </span>
        </a>
      </li>
    ));
  };
  
  render() {
    return (
      <BlankWrapper>

        <div className="flex items-center h-screen max-w-screen-xl mx-auto">
          <div className="fixed items-center flex-1 hidden h-screen md:flex">
            <div className="card p-0 pt-10 w-[24rem] flex flex-col gap-5">
              <h3 className="px-10">Get started with LinePilot in just a few minutes...</h3>
              <div className="px-10">{this.stepsList(this.state.onboarding)}</div>
              <div className="flex flex-row items-center gap-10 px-10 pt-5 pb-5 border-t border-toneMid">
                <h3>Need Help?</h3>
                <a
                  href="mailto:support@weaveandblend.com"
                  className="text-sm text-primary hover:underline"
                >
                  Contact Support
                </a>
              </div>
            </div>
          </div>
          <motion.div
            className="flex flex-col flex-1 h-screen px-10 md:px-0 md:ml-[24rem] justify-start tall:justify-center"
            key="1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            {this.onboardingSteps(this.state.onboarding)}
          </motion.div>
        </div>
      </BlankWrapper>
    );
  }
}

export default Onboarding;
