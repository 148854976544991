import React from "react";
import { Link, navigate } from "@reach/router";
import { motion } from "framer-motion";
import { DateTime } from "luxon";

import Config from "../config";
import Session from "../components/Session";
import LoadingState from "../components/states/LoadingState";
import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";


class Invite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      loading: false,
      error: false,
      errorDetails: false,
      success: false,
    };
    this._isMounted = false;
  }

  componentDidMount = async () => {
    document.title = `Invite ${Config.titleSuffix}`;
    this._isMounted = true;
    let user = await Session.getUserData();

    this.setState({ pageLoading: false, code: user.referralCode });
  };
  
  componentWillUnmount() {
    this._isMounted = false;
  }
  
 


  render() {

  

  return (
    <>
      {this.state.pageLoading ? (
        <LoadingState />
      ) : (
        <>
          <motion.div
            key="1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex flex-row h-screen max-w-screen-xl gap-10 mx-auto">
              <div className="flex-row flex-1 w-full">
                <header className="flex flex-row pb-10 mb-5 border-b mt-36 border-tone-500">
                  <div className="flex flex-col items-baseline flex-grow gap-5">
                    <h1 className="">Invite</h1>
                    <p>
                      Here is your exclusive invite code for LinePilot. Share it with your friends and colleagues to get them started with LinePilot.
                    </p>
                  </div>
                </header>

                <div className="my-10">
                  <h1
                    className="p-10 bg-white border border-tone-500 focus:outline-none"
                    contentEditable
                    spellcheck="false"
                  >
                    {this.state.code}
                  </h1>
                  <p className="mt-5">
                    Direct users to <a href="https://app.linepilot.co/onboarding">https://app.linepilot.co/onboarding</a> to sign up
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </>
  );
  }
}

export default Invite;
