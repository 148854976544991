import React from "react";

import { ReactComponent as HubspotLogo } from "../assets/images/logo-hubspot.svg";
import { Puff } from "svg-loaders-react";

import {
  RefreshIcon,
  LockClosedIcon,
  PlusIcon,
  MailIcon,
  WrenchIcon,
  PencilIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

import LoadingState from "../components/states/LoadingState";
import Config from "../config";
import Session from "../components/Session";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";

class HubSpot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      loading: true,
      connected: false,
      hubspot: "HubSpot",
      propertyStatus: false,
      propertySuccess: false,
      propertyError: false,
      propertyLoading: false,
      error: false,
    };
  }
  componentDidMount = async () => {
    document.title = `HubSpot ${Config.titleSuffix}`;
    await this.getStatus();
    this.setState({ pageLoading: false });
  };
  async getStatus() {
    try {
      let requestUrl = Config.api.root + "/connections/hubspot/check";
      let response = await fetch(requestUrl, Config.api.options);
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.error) {
        this.setState({
          connected: true,
          hubspot: response.hubspot,
          error: true,
        });
      }
      if (response.hubspot) {
        this.setState({
          connected: true,
          hubspot: response.hubspot,
          propertyStatus: response.propertyStatus,
        });
      } else {
        this.setState({ connected: false });
      }
      this.setState({ loading: false });
      console.log(response);
    } catch (error) {
      if (error === 403) {
        Session.logout(this.props?.location?.pathname);
      }
      this.setState({ loading: false });
      console.error(error);
    }
  }

  async disconnect() {
    try {
      let requestUrl = Config.api.root + "/connections/hubspot/disconnect/";
      let response = await fetch(requestUrl, Config.api.options);
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.error) {
        throw response.error;
      }
      console.log(response);
      this.getStatus();
    } catch (error) {
      if (error === 403) {
        Session.logout(this.props.location.pathname);
      }
      console.error(error);
    }
  }

  async createProperties() {
    try {
      this.setState({ propertyLoading: true });
      let requestUrl = Config.api.root + "/admin/properties/create";
      let response = await fetch(requestUrl, Config.api.options);
      if (response.status !== 200) {
        throw response.status;
      }
      response = await response.json();
      if (response.error) {
        throw response.error;
      }
      this.setState({ propertyLoading: false, propertySuccess: true, properties: true });
      console.log(response);
    } catch (error) {
      this.setState({ propertyLoading: false, propertyError: true });
      if (error === 403) {
        Session.logout(this.props.location.pathname);
      }
      console.error(error);
    }
  }

  render() {
    return (
      <>
        {this.state.pageLoading ? (
          <LoadingState />
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-screen">
            <div className="p-16 mx-auto">
              <h2 className="mb-2">Add LinePilot to HubSpot</h2>
              <p>
                This gives LinePilot permissions to read and write data to your HubSpot account.
              </p>
              <div className="max-w-3xl mx-auto">
                {this.state.connected ? (
                  <>
                    {/* {!this.state.propertyStatus && !this.props.onboarding ? (
                          <div className="items-center mt-6 text-center">
                            {this.state.propertyLoading ? (
                              <button
                                className="inline-flex items-center px-16 py-2 mt-4 leading-6 text-gray-600 transition duration-150 ease-in-out bg-white border border-gray-400 rounded-lg cursor-pointer text-md min-w-sm outline-gray-900 outline-2 hover:text-gray-700"
                                type="submit"
                              >
                                <Puff className="w-6 h-6 mr-2 stroke-gray-600" />
                                Loading...
                              </button>
                            ) : (
                              <button
                                className="items-center px-16 py-2 mt-4 leading-6 text-gray-600 transition duration-150 ease-in-out bg-white border border-gray-400 rounded-lg cursor-pointer text-md min-w-sm outline-gray-900 outline-2 hover:text-gray-700"
                                onClick={(e) => this.createProperties(e)}
                              >
                                Create Properties
                              </button>
                            )}
                          </div>
                        ) : (
                          ""
                        )} */}

                    <div className="flex items-center p-3 my-10 card">
                      <HubspotLogo className="w-24 h-24" />
                      <div className="flex flex-col ml-4 -mt-2">
                        {!this.state.error ? (
                          <div className="text-sm font-medium text-primary">Connected</div>
                        ) : (
                          <div className="text-sm font-medium text-yellow-600">
                            <ExclamationTriangleIcon className="inline w-4 h-4" /> Connection Error
                          </div>
                        )}
                        <div className="block text-lg leading-4">{this.state.hubspot}</div>
                      </div>

                      <div className="flex flex-row items-center flex-1 w-full gap-2 text-right md:items-end md:mt-0 md:flex-col ">
                        <div className="">
                          <a
                            className="button"
                            href={Config.api.root + "/connections/hubspot/connect"}
                          >
                            <ArrowPathIcon className="w-6 h-6" />
                            Reconnect
                          </a>
                        </div>

                        <button
                          className="text-red-600 button-text"
                          onClick={(e) => this.disconnect()}
                        >
                          <XMarkIcon className="w-3 h-3" />
                          Disconnect from HubSpot
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-4">
                      <a
                        href={Config.api.root + "/connections/hubspot/connect"}
                        className="button-primary"
                      >
                        <HubspotLogo className="w-8 h-8 -ml-5 fill-white" />
                        Start Connecting
                      </a>
                    </div>
                  </>
                )}
              </div>
              {this.props.onboarding && (
                <div className="mt-4">
                  <button
                    onClick={(e) => this.state.connected && this.props.updateOnboarding(3)}
                    className={
                      this.state.connected
                        ? "button-primary"
                        : "button-primary-disabled cursor-default"
                    }
                  >
                    Continue
                  </button>
                </div>
              )}
              <Modal
                open={this.state?.error}
                type="error"
                title={this.state.errorDetails?.title}
                message={this.state.errorDetails?.message}
                setClose={() => {
                  this.setState({ error: false }),
                    setTimeout(() => this.setState({ errorDetails: false }), 500);
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

export default HubSpot;
