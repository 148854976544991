import React from "react";
import { Link, navigate } from "@reach/router";

import Session from "../components/Session";
import Message from "../components/modals/Message";
import Modal from "../components/modals/Modal";
import Config from "../config";
import BlankWrapper from "../wrappers/BlankWrapper";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      errorDetails: false,
      success: false,
      username: "",
      password: "",
    };
  }
  componentDidMount = async () => {
    document.title = "Log In" + Config.titleSuffix;
  };
  async handleSubmit(e) {
    e.preventDefault();
    try {
      this.setState({ loading: true });
      let requestUrl = Config.api.root + "/auth/login";
      let options = {
        method: "POST",
        body: JSON.stringify({
          username: this.state.username,
          password: this.state.password,
        }),
      };
      let response = await fetch(requestUrl, {
        ...options,
        ...Config.api.options,
      });
      if (response.status !== 200) {
        throw response.status;
      }
      
      response = await response.json();
      if (response.error) {
        throw response.error;
      }
      Session.setUserData(response);

      var _hsq = (window._hsq = window._hsq || []);
      _hsq.push(["identify", { email: response.user.username }]);

      // if (response.user.onboarding !== "complete") {
      //   localStorage.setItem("isOnboarding", 1);
      //   navigate("/onboarding");
      // } else if (this.props.location.search) {
        if (this.props.location.search) {
          let redirect = this.props.location.search.replace("?redirect=", "");
          navigate(redirect);
        } else {
          navigate("/");
        }
    } catch (error) {
      if(error === "user_not_found") {
        error = "User not found - please check email and try again"
      } if (error === "passwords_do_not_match") {
        error = "Incorrect password - please check password and try again";
      }
      this.setState({
        loading: false,
        error: true,
        errorDetails: {
          title: "Login Failed",
          message: typeof error === "string" ? error : "Couldn't log you in - please check password and try again"
        }
      });
    }
  }
  render() {
    return (
      <BlankWrapper>
        <div className="flex flex-row items-center justify-center max-w-screen-xl min-h-screen mx-auto">
          <div className="flex flex-col w-full max-w-sm">
            <h2 className="text-center">Log In</h2>

            <Message open={this.state.loading} type="loading" title="Logging in..." />
            <Modal
              open={this.state?.error}
              type="error"
              title={this.state.errorDetails.title}
              message={this.state.errorDetails.message}
              setClose={() => {
                this.setState({ error: false }),
                  setTimeout(() => this.setState({ errorDetails: false }), 500);
              }}
            />

            <form
              className="flex flex-col flex-1 w-full gap-4"
              onSubmit={(e) => this.handleSubmit(e)}
            >
              <div>
                <label htmlFor="email">Email</label>
                <div>
                  <input
                    type="email"
                    value={this.state.username}
                    onChange={(e) => this.setState({ username: e.target.value })}
                    required
                    tabIndex="1"
                    className="w-full"
                    autoComplete="username"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password">
                  Password
                  <Link to="password-reset" className="flex-1 text-sm text-right" tabIndex="4">
                    Forgotten Password?
                  </Link>
                </label>
                <div>
                  <input
                    type="password"
                    value={this.state.password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                    tabIndex="2"
                    required
                    className="w-full"
                    autoComplete="current-password"
                  />
                </div>
              </div>

              <div className="text-center">
                <input
                  className="button-primary"
                  type="submit"
                  value="Log In"
                  tabIndex="3"
                  disabled={this.state.loading}
                />
              </div>
            </form>
          </div>
        </div>
      </BlankWrapper>
    );
  }
}

export default Login;
