import React, { useState, forwardRef, useImperativeHandle } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

const QuestionBlock = forwardRef(({ title, children }, ref) => {
      const [isOpen, setIsOpen] = useState(false);
       
      const toggleOpen = () => setIsOpen(!isOpen);

      // Expose the toggleOpen function to parent components
      useImperativeHandle(ref, () => ({
        toggleOpen,
      }));
      function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }

      return (
        <div class="py-3">
          <dt class="text-lg">
            <button
              type="button"
              class="flex items-start justify-between w-full text-left"
              onClick={toggleOpen}
            >
              <span class="font-medium " itemprop="name">
                {title}
              </span>
              <span class="flex items-center ml-6 h-7">
                <ChevronDownIcon
                  class={classNames(
                    "w-6 h-6 transition-transform duration-300 ease-in-out rotate-0 text-primary",
                    isOpen && "rotate-180"
                  )}
                />
              </span>
            </button>
          </dt>
          <dd class={classNames("pr-12 mt-3 ml-3", !isOpen && "hidden")}>
            <div class="py-3" itemprop="text">
              {children}
            </div>
          </dd>
        </div>
      );
    });

export default QuestionBlock;
