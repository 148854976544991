import { navigate } from "@reach/router";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { DateTime } from "luxon";


export default function TrialBlock({ trialExpiry, runCount, runLimit, showButton = false }) {
  trialExpiry = DateTime.fromISO(trialExpiry);
  const trialDaysLeft = trialExpiry.diffNow("days").days.toFixed(0);

  return (
    <div className="p-4 border rounded-lg border-amber-200 bg-amber-100 text-amber-600">
      <h3>Your trial expires {trialDaysLeft != 0 && "in"}</h3>
      <p className="my-2 text-4xl">
        {trialDaysLeft == 0 ? "Today" : <>{trialDaysLeft} day{trialDaysLeft !== 1 && "s"}</>}
      </p>
      <p className="text-sm">
        You have used {runCount} of your {runLimit} trial runs
      </p>
      {showButton && (
      <button
        className="my-2 button-primary bg-amber-500 hover:bg-amber-600 border-amber-500 hover:border-amber-600"
        onClick={() => navigate("/billing")}
      >
        <SparklesIcon className="w-6 h-6 p-1 text-white transition-colors rounded-full" />
        See Upgrade Options
      </button>
      )}
    </div>
  );
}
